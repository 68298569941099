<template>
  <div class="detail" v-loading="loading">
    <Breadcrumb separator="->" :list="breadcrumbList"></Breadcrumb>
    <div class="detail-box">
      <Title title="搜索结果"></Title>
      <ul style="padding: 10px 0 0 20px;">
        <div v-if="noData" class="no-data">
          <img :src="img" alt="">
        </div>
        <li v-for="(item, index) of list" :key="index"  class="search-news-li clearfix news-li-border">
          <div class="search-news-li-icon pull-left">
            <img v-if="item.rtype == 4 || item.rtype == 3" :src="pdf" alt="">
            <img v-else :src="article" alt="" style="width: 13px;margin: auto;">
          </div>
          <!-- <div class="news-left pull-left" style="width: 420px;" @click="itemClick(item.id)" v-html="item.title">{{item.title}}</div> -->
          <div class="news-left pull-left" style="width: 420px;" @click="itemClick(item)" v-html="item.title"></div>
          <div class="pull-left" style="padding-left: 15px;">
            <span v-for="(son, ind) of item.breadcrumb" :key="ind">
              <span class="breadcrumbItem cursor" @click="breadcrumbItem(son.url)">{{son.name}}</span>
              <span v-if="ind < item.breadcrumb.length - 1" style="margin: 0 2px;">-></span>
            </span>
          </div>
          <div v-if="item.rtype == 4" class="unload-btns">
            <div class="unload-btn" @click="fileDetail(item.attFiles)" style="margin-right: 10px;">
              <img :src="previewIcon" alt="" class="un-icon">
              <span>预览</span>
            </div>
            <div class="unload-btn" @click="unlaod(item.attFiles, item.title)">
              <!-- <i class="el-icon-s-flag"></i> -->
              <img :src="unlaodIcon" alt="" class="un-icon">
              <span>下载</span>
            </div>
          </div>
          <div v-else class="news-time">{{item.publishTime}}</div>
        </li>
      </ul>
      <Pagination :total="total" :size="size" :page="current" @PaginationFun="PaginationFun"></Pagination>
    </div>
  </div>
</template>

<script>
import { wpagesList, wpagesIpagesList, wpagesGlobalList, wpagesGlobalIpagesList } from '@/api/web'
import { fileDownload } from '@/api/login.js'
import Title from '@/components/title/Title'
import Pagination from '@/components/pagination/Pagination.vue'
export default {
  components: {
    Title,
    Pagination
  },
  name: 'ListSearch',
  data () {
    return {
      unlaodIcon: require('../../../assets/images/unload_b.png'),
      previewIcon: require('../../../assets/images/preview.png'),
      img: require('../../../assets/images/nodata.png'),
      // pdf: require('../../../assets/images/icon_pdf.png'),
      pdf: require('../../../assets/images/wenjian.png'),
      article: require('../../../assets/images/icon_w.png'),
      noData: false,
      loading: false,
      breadcrumbList: [
        { name: '首页', path: '/' },
        { name: '搜索结果', path: '' }
      ],
      list: [],
      total: 0,
      current: 1,
      size: 10
    }
  },
  watch: {
    '$route.fullPath': function (n, o) {
      this.current = 1
      this.initialData()
    }
  },
  created () {
    this.initialData()
  },
  methods: {
    fileDetail (path) {
      window.open(process.env.VUE_APP_IMG_URL + path, '_blank')
    },
    unlaod (path, name) {
      fileDownload(path, name)
    },
    itemClick (item) {
      let itemUrl = ''
      if (item.rtype === 2) {
        itemUrl = `/unitdetail?id=${item.typeIds[0]}&articleId=${item.id}`
      } else if (item.rtype === 3) {
        itemUrl = `/materialdetail?id=${item.typeIds[0]}&articleId=${item.id}`
      } else if (item.rtype === 4) {
        window.open(process.env.VUE_APP_IMG_URL + item.attFiles, '_blank')
        return
      } else if (item.rtype === 6) {
        itemUrl = `/votedetail?id=${item.typeIds[0]}&articleId=${item.id}`
      } else {
        itemUrl = `/detail?id=${item.typeIds[0]}&articleId=${item.id}`
      }
      const { href } = this.$router.resolve({
        path: itemUrl
      })
      window.open(href, '_blank')
    },
    breadcrumbItem (url) {
      const { href } = this.$router.resolve({
        path: url
      })
      window.open(href, '_blank')
    },
    initialData () {
      this.loading = true
      const data = {
        keyword: this.$route.query.text,
        pageSize: this.size,
        pageNo: this.current
      }
      if (this.$route.query.type === '1') {
        // 搜本页
        data.id = this.$route.query.id
        if (window.localStorage.getItem('token')) {
          wpagesIpagesList(data).then(res => {
            this.loading = false
            if (res.code === '000') {
              this.setData(res)
            }
          })
        } else {
          wpagesList(data).then(res => {
            this.loading = false
            if (res.code === '000') {
              this.setData(res)
            }
          })
        }
      } else {
        if (window.localStorage.getItem('token')) {
          wpagesGlobalIpagesList(data).then(res => {
            this.loading = false
            if (res.code === '000') {
              this.setData(res)
            }
          })
        } else {
          wpagesGlobalList(data).then(res => {
            this.loading = false
            if (res.code === '000') {
              this.setData(res)
            }
          })
        }
      }
    },
    setData (res) {
      const list = res.result.records
      list.forEach((value, index) => {
        if (value.typeIds.indexOf(',') !== -1) {
          value.typeIds = value.typeIds.split(',')
          value.typeNames = value.typeNames.split(',')
        } else {
          value.typeIds = [value.typeIds]
          value.typeNames = [value.typeNames]
        }
        const breadcrumb = []
        let itemUrl = '/list/normal'
        if (value.typeUrls) {
          itemUrl = value.typeUrls
        }
        value.typeNames.forEach((val, ind) => {
          if (ind === 0) {
            breadcrumb.push({ name: val, url: `${itemUrl}?id=${value.typeIds[ind]}` })
          } else {
            if (value.type === '2') {
              breadcrumb.push({ name: val, url: `${itemUrl}?id=${value.typeIds[0]}&columnId=${value.typeIds[ind]}` })
            } else {
              breadcrumb.push({ name: val, url: `${itemUrl}?id=${value.typeIds[0]}&cid=${value.typeIds[ind]}` })
            }
          }
        })
        value.breadcrumb = breadcrumb
        const valIndex = value.title.indexOf(this.$route.query.text)
        const valLength = this.$route.query.text.length
        const text = `<span class="news-left-span">${this.$route.query.text}</span>`
        value.title = value.title.substring(0, valIndex) + text + value.title.substr(valIndex + valLength)
      })
      this.list = list
      this.total = res.result.total
      this.current = res.result.current
      if (res.result.total === 0) {
        this.noData = true
      } else {
        this.noData = false
      }
    },
    PaginationFun (e) {
      this.current = e
      this.initialData()
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/style/list.css";
.detail{
  padding-bottom: 30px;
}
.detail-box{
  padding: 20px 60px 80px 30px;
  min-height: 400px;
  background-color: #ffffff;
  position: relative;
}
.breadcrumbItem:hover{
  color: #155BDD;
}
.unload-btns{
  height: 26px;
  position: absolute;
  top: 50%;
  margin-top: -13px;
  right: 10px;
}
.unload-btn{
  display: inline-block;
  font-size: 13px;
  padding: 0 15px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #4E84FF;
  line-height: 26px;
  color: #ffffff;
  cursor: pointer;
}
.unload-btn:hover{
  opacity: 0.7;
}
.un-icon{
  height: 12px;
  width: 14px;
  vertical-align: middle;
  margin-right: 5px;
  position: relative;
  top: -2px;
}
</style>
