<template>
  <div v-if="total > 0" class="pagination-box" :style="`text-align: ${align};`">
    <!-- <span class="font-color-h mar-right">共 {{total}} 条</span>
    <span class="font-color-h">{{size}} 条/页</span> -->
    <div class="page-number-box">
      <span class="arrows-span arrows-span-left"
            :class="[contPage != 1 && leftMouse?'arrows-hover':'', contPage == 1?'arrows-allowed':'']"
            @mouseover="leftMouseOver"
            @mouseleave="leftMouseLeave"
            @click="minus">
        <i class="arrows-span-span"></i>
      </span>
      <span class="page-number cursor" style="margin-left: 10px;"
            :class="{'page-number-on':contPage == 1}"
            @click="numClick(1)"
            >1</span>
      <span v-if="dianLeft" class="pagination-dian cursor">...</span>
      <span class="page-number cursor"
            v-for="(num, index) of arr" :key="index"
            :class="{'page-number-on':contPage == num}"
            @click="numClick(num)"
            >{{num}}</span>
      <span v-if="dianRight" class="pagination-dian cursor">...</span>
      <span v-if="total > 10" class="page-number cursor" style="margin-right: 10px;"
           :class="{'page-number-on':contPage == sumPage}"
            @click="numClick(sumPage)"
           >{{sumPage}}</span>
      <span class="arrows-span arrows-span-right"
            :class="[contPage != sumPage && rightMouse && total > size?'arrows-hover':'', contPage == sumPage || total < size + 1?'arrows-allowed':'']"
            @mouseover="rightMouseOver"
            @mouseleave="rightMouseLeave"
            @click="plus">
        <i class="arrows-span-span" style="transform: rotate(-45deg);"></i>
      </span>
    </div>
    <div class="page-input-box">
      <span>第</span>
      <input class="page-input" type="text" :value="inputValue"
             oninput ="value=value.replace(/[^\d]/g,'')"
             @change="inputChange($event)">
      <span>页</span>
      <span class="btn search pagination-btn" @click="toContPage">前往</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'Pagination',
  data () {
    return {
      dianLeft: false,
      dianRight: false,
      leftMouse: false,
      rightMouse: false,
      inputValue: '',
      sumPage: '',
      contPage: 1,
      arr: []
    }
  },
  props: {
    align: {
      type: String,
      default: 'right'
    },
    size: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    }
  },
  watch: {
    '$props.total': function (n, o) {
      if (n > 0) {
        this.initialData()
      }
    }
  },
  methods: {
    initialData () {
      this.contPage = this.$props.page
      this.inputValue = this.$props.page
      if ((this.$props.total / this.$props.size + '').indexOf('.') > 0) {
        this.sumPage = parseInt(this.$props.total / this.$props.size) + 1
      } else {
        this.sumPage = this.$props.total / this.$props.size
      }
      this.setArr()
    },
    setArr () {
      const arr = []
      if (this.sumPage <= 7) {
        this.dianRight = false
        this.dianLeft = false
        for (let i = 2; i < this.sumPage; i++) {
          arr.push(i)
        }
      } else {
        if (this.contPage < 5) {
          this.dianRight = true
          this.dianLeft = false
          for (let i = 2; i < 7; i++) {
            arr.push(i)
          }
        } else {
          if (this.contPage < this.sumPage - 3) {
            this.dianRight = true
            this.dianLeft = true
            for (let i = this.contPage - 2; i < this.contPage + 3; i++) {
              arr.push(i)
            }
          } else {
            this.dianLeft = true
            this.dianRight = false
            for (let i = this.sumPage - 5; i < this.sumPage; i++) {
              arr.push(i)
            }
          }
        }
      }
      this.arr = arr
    },
    // 点击数字
    numClick (num) {
      if (this.contPage === num) {
        return
      }
      this.contPage = num
      this.inputValue = num
      this.setArr()
      this.returnData()
    },
    // 监听input变化
    inputChange (e) {
      const val = e.target.value
      if (!val || val === '0') {
        this.inputValue = ''
        this.inputValue = 1
      } else if (val > this.sumPage) {
        this.inputValue = ''
        this.inputValue = this.contPage
      } else {
        this.inputValue = parseInt(val)
      }
    },
    // 点击前往按钮
    toContPage () {
      if (this.inputValue === this.contPage) {
        return
      }
      this.contPage = this.inputValue
      this.setArr()
      this.returnData()
    },
    // 鼠标划入左箭头
    leftMouseOver () {
      this.leftMouse = true
    },
    // 鼠标划出左箭头
    leftMouseLeave () {
      this.leftMouse = false
    },
    // 鼠标划入右箭头
    rightMouseOver () {
      this.rightMouse = true
    },
    // 鼠标划出右箭头
    rightMouseLeave () {
      this.rightMouse = false
    },
    // 右 加
    plus () {
      if (this.contPage === this.sumPage || this.$props.total < this.$props.size + 1) {
        return
      }
      this.numClick(this.contPage + 1)
    },
    // 左 减
    minus () {
      if (this.contPage === 1) {
        return
      }
      this.numClick(this.contPage - 1)
    },
    // 向父组件传递数据
    returnData () {
      this.$emit('PaginationFun', this.contPage)
    }
  }
}
</script>

<style lang="less" scoped>
.pagination-box{
  position: absolute;
  bottom: 25px;
  right: 25px;
  // padding: 10px 15px;
  // padding: 0 15px;
  font-size: 14px;
}
.font-color-h{
  color: #888888;
  line-height: 32px;
  vertical-align: middle;
}
.mar-right{
  margin-right: 15px;
}
.page-input-box{
  display: inline-block;
  vertical-align: middle;
  line-height: 34px;
}
.page-input{
  line-height: 32px;
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  width: 50px;
  cursor: pointer;
  margin: 0 5px;
  padding: 0 5px;
  text-align: center;
  font-size: 15px;
  vertical-align: middle;
}
.page-input:hover{
  border: 1px solid #b0afaf;
}
.page-input:focus{
  border: 1px solid #568dfc;
}
.pagination-btn{
  vertical-align: middle;
  line-height: 34px;
  font-size: 12px;
  padding: 0 10px;
  margin-left: 10px;
}
.page-number-box{
  display: inline-block;
  margin: 0 20px;
  vertical-align: middle;
}
.page-number-box span{
  margin: 0 4px;
}
.arrows-span{
  vertical-align: middle;
  min-width: 32px;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  position: relative;
}
.arrows-span-span{
  height: 10px;
  width: 10px;
  border-right: 2px solid #999999;
  border-bottom: 2px solid #999999;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  left: 50%;
  margin-left: -5px;
  transform: rotate(135deg);
}
.arrows-span-left{
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-right: 10px;
}
.arrows-span-right{
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-left: 10px;
}
.arrows-hover{
  cursor: pointer;
}
.arrows-hover .arrows-span-span{
  border-right: 2px solid #568dfc;
  border-bottom: 2px solid #568dfc;
}
.arrows-allowed{
  cursor: not-allowed;
}
.pagination-dian{
  font-size: 20px;
  letter-spacing: 1px;
  position: relative;
  top: -3px;
}
.page-number{
  vertical-align: middle;
  height: 32px;
  min-width: 32px;
  line-height: 30px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}
.page-number:hover{
  color: #568dfc;
}
.page-number-on{
  color: #1890FF;
  font-weight: bold;
  border: 1px solid #1890FF;
}
</style>
