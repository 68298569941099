<style scoped>
.title-bt{
  height: 50px;
  padding-left: 20px;
  line-height: 50px;
  position: relative;
  font-size: 26px;
}

.color-block::before{
  content: '';
  height: 30px;
  width: 4px;
  background-color: #FF9852;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 10px;
}
</style>

<template>
    <div class="title-bt" :class="{'color-block':colorBlock}">
      <span>{{title}}</span>
    </div>
</template>

<script>
export default {
  name: 'Title',
  data () {
    return {}
  },
  props: {
    title: {
      type: String,
      default: '标题'
    },
    colorBlock: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  watch: {},
  created () {},
  methods: {

  }
}
</script>
